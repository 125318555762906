import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GlobalStyles } from '../../../styles/globalStyles';
import ButtonCircle from '../../../atoms/buttonCircle';
import { colors, media } from '../../../styles/theme';
import CardSvg from '../../../assets/svgs/card.svg';
import LockedCard from '../../../assets/svgs/card-with-lock.svg';
import LoadingSpinner from '../../../atoms/loadingSpinner';
import { lockOrUnlockCard, getCard } from '../../../async/card/card';
import { Link } from 'gatsby';
import { paths } from '../../../const/paths';
import { navigate } from 'gatsby';
import { Card as CardEntity, CardStatus } from '../../../api/card/model';

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  transform: translate(-50%, -50%);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3vh;
  ${media.tablet`
    padding-top: 10vh;
  `}
`;

const StyledCard = styled(CardSvg)`
  height: 300px;
`;

const StyledLockedCard = styled(LockedCard)`
  margin-right: 43px;
  margin-bottom: 16.5px;
  height: 300px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 300px;
  ${media.tablet`
    top: 400px;
  `}
`;

const Button = styled(Link)`
  text-decoration: none;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 20px;
  font-color: ${colors.darkGrey};
  font-family: 'GilroySemiBold';
  width: 180px;
  height: 50px;
  background-color: ${colors.brandYellow};
  &:active {
    opacity: 0.5;
  }
`;

const LockedWrapper = styled.div`
  background-color: ${colors.backgroundGrey};
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
`;

const Text = styled.p`
  margin-top: 0px;
`;

const LoadingWrapper = styled.div`
  margin-top: 40px;
  margin-left: 20px;
`;

const Card: React.FC = () => {
  const [isLock, setIsLock] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [card, setCard] = useState<CardEntity>();

  useEffect(() => {
    retrieveCardId();
  }, []);

  const retrieveCardId = async () => {
    const data = await getCard();
    if (data && data.id) {
      return setCard(data);
    }
    navigate(paths.emergencyAccess);
  };

  const lockCard = async () => {
    if (card) {
      setIsLoading(true);
      await lockOrUnlockCard(card.id, 'lock');
      setIsLoading(false);
      setIsLock(false);
    }
  };

  return (
    <Wrapper>
      <GlobalStyles />
      {card && card.id !== '' && (
        <Content>
          {isLock ? <StyledCard /> : <StyledLockedCard />}
          {!isLoading ? (
            <>
              {card && card.status === CardStatus.ACTIVE ? (
                <ButtonWrapper>
                  {isLock ? (
                    <ButtonCircle
                      onClick={lockCard}
                      title="Lock card"
                      type="lock"
                    />
                  ) : (
                    <LockedWrapper>
                      <Text>Your card is now locked</Text>
                      <Button to={paths.home}>Done</Button>
                    </LockedWrapper>
                  )}
                </ButtonWrapper>
              ) : (
                <Text>Your card can't be frozen right now.</Text>
              )}
            </>
          ) : (
            <LoadingWrapper>
              <LoadingSpinner />
            </LoadingWrapper>
          )}
        </Content>
      )}
    </Wrapper>
  );
};

export default Card;
