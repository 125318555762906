import React from 'react';
import { Wrapper, Button, Lock, Key } from './styles';

export type ButtonCircleProps = {
  onClick: () => void;
  title: string;
  type: 'lock' | 'key';
};

const ButtonCircle = ({ onClick, title, type }: ButtonCircleProps) => {
  return (
    <Wrapper>
      <Button onClick={onClick}>{type === 'lock' ? <Lock /> : <Key />}</Button>
      <p>{title}</p>
    </Wrapper>
  );
};

export default ButtonCircle;
