export type LockUnlock = 'lock' | 'unlock';

export type Card = {
  id: string;
  activated: boolean;
  locked: boolean;
  status: CardStatus;
};

export enum CardStatus {
  INACTIVE = 'INACTIVE',
  NOT_YET_ACTIVE = 'NOT_YET_ACTIVE',
  ACTIVE = 'ACTIVE'
}

export const cardInitialState = {
  id: '',
  activated: false,
  locked: false,
  status: CardStatus.ACTIVE
};
