import axios from 'axios';
import { config } from '../../config/config';
import { LockUnlock } from './model';

export const api = {
  async lockOrUnlockCard(cardId: string, lockUnlock: LockUnlock) {
    const response = await axios.post(
      `${config.url}/api/v1/cards/${cardId}/${lockUnlock}`
    );
    return response.status;
  },
  async getCard() {
    const response = await axios.get(`${config.url}/api/v1/cards/primary`);
    return response.data.data;
  }
};
