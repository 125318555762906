import axios from 'axios';
import { config } from '../../config/config';

export const api = {
  async sendAuthenticationEmail(email: string) {
    const response = await axios.post(
      `${config.gatekeeperUrl}/api/v1/authenticate/restricted/email`,
      { email }
    );
    return response.status;
  }
};
