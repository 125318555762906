import { api } from '../../api';
import { LockUnlock, Card } from '../../api/card/model';
import { navigate } from 'gatsby';
import { paths } from '../../const/paths';

export const lockOrUnlockCard = async (
  cardId: string,
  lockUnlock: LockUnlock
) => {
  try {
    await api.lockOrUnlockCard(cardId, lockUnlock);
  } catch (error) {
    navigate(paths.emailExpired);
  }
};

export const getCard = async (): Promise<Card | undefined> => {
  try {
    const card = await api.getCard();
    return card;
  } catch (error) {
    navigate(paths.emailExpired);
  }
};
